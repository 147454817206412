























import { Component } from 'vue-property-decorator'
import Header from '@/components/Header.vue'

import { Job } from '../jobs.types'
import { ApiError, Button } from '@focus/components'
import BaseComponent from '@/plugins/mixins'
import { Group } from '@/domains/groups'
import NoGroupCard from '@/domains/groups/components/NoGroupCard.vue'

@Component({
  components: {
    Button,
    Header,
    NoGroupCard
  }
})
export default class JobReadPage extends BaseComponent {
  job: Job | null = null

  groups: Group[] = []

  get jobId () {
    return this.$route.params.id
  }

  async mounted () {
    try {
      this.job = await this.$store.dispatch('jobs/getSingle', { id: this.jobId })
      this.groups = await this.$store.dispatch('groups/rawGetMany', { params: { jobId: this.jobId } })
    } catch (error) {
      if (error instanceof ApiError) {
        await this.showError(error)
      } else {
        throw error
      }
    }
  }

  async registerInterest () {
    try {
      await this.$store.dispatch('jobs/registerInterest', { id: this.jobId })
    } catch (error) {
      if (error instanceof ApiError) {
        await this.showError(error)
      } else {
        throw error
      }
    }
  }
}
